<template>
  <!-- BEGIN login -->
  <div class="login">
    <!-- BEGIN login-content -->
    <div class="login-content">
      <validation-observer v-slot="{ invalid }">
        <form v-if="!forgot" @submit.prevent="submitLoginRequest">
          <h1 class="text-center">Sign In</h1>
          <div class="text-muted text-center mb-4">
            For your protection, please verify your identity.
          </div>

          <b-alert :show="!!pageError" variant="danger">
            {{ pageError }}
          </b-alert>

          <b-overlay :show="isLoginLoading">
            <validation-provider name="Email Address" v-slot="context" rules="required|email">
              <b-form-group label="Email Address" label-for="email-address">
                <b-input
                  v-model="email"
                  id="email-address"
                  type="email"
                  size="lg"
                  class="fs-15px"
                  autocomplete="username"
                  placeholder="username@address.com"
                  :state="getValidationState(context)"
                  aria-describedby="email-address-error"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback id="email-address-error" v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="Password" rules="required" v-slot="context">
              <b-form-group>
                <div class="d-flex">
                  <label for="password">Password</label>
                </div>

                <b-input
                  id="password"
                  type="password"
                  size="lg"
                  autocomplete="current-password"
                  class="fs-15px"
                  placeholder="Enter your password"
                  v-model="password"
                  :state="getValidationState(context)"
                  aria-describedby="password-error"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback id="password-error" v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <button type="submit" class="btn btn-primary btn-lg btn-block fw-500 mb-3" :disabled="invalid">
              Sign In
            </button>
          </b-overlay>

          <a @click.prevent="forgot = true" class="ml-auto text-muted" style="cursor: pointer">
            Glemt passord?
          </a>
        </form>

        <form v-if="forgot" @submit.prevent="submitForgotRequest">
          <h1 class="text-center">Glemt passord</h1>
          <div class="text-muted text-center mb-4">
            Fyll inn e-postadressen du har registrert hos oss og trykk ”Send”. Du får da tilsendt instruksjoner om
            hvordan du lager et nytt passord til din e-postadresse.
          </div>

          <b-alert :show="!!pageError" variant="danger">
            {{ pageError }}
          </b-alert>

          <validation-provider name="Email Address" v-slot="context" rules="required|email">
            <b-form-group label="Email Address" label-for="email-address">
              <b-input
                v-model="email"
                id="email-address"
                type="email"
                size="lg"
                class="fs-15px"
                autocomplete="username"
                placeholder="username@youwish.no"
                :state="getValidationState(context)"
                aria-describedby="email-address-error"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback id="email-address-error" v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <button type="submit" class="btn btn-primary btn-lg btn-block fw-500 mb-3" :disabled="invalid">
            Send
          </button>
          <a @click.prevent="forgot = false" class="ml-auto text-muted" style="cursor: pointer">
            Tilbake til login
          </a>
        </form>
      </validation-observer>
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login -->
</template>

<script>
import AppOptions from '../config/app-options'
import { getValidationState } from './page-helpers'

export default {
  name: 'LoginPage',
  inject: ['$cannot'],
  data() {
    return {
      email: '',
      password: '',
      pageError: '',
      forgot: false,
      isLoginLoading: false,
    }
  },
  mounted() {
    AppOptions.appWithoutSidebar = true
    AppOptions.appWithoutHeader = true
    AppOptions.appContentFullWidth = true
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appWithoutSidebar = false
    AppOptions.appWithoutHeader = false
    AppOptions.appContentFullWidth = false
    next()
  },
  methods: {
    getValidationState,
    async submitLoginRequest() {
      try {
        this.isLoginLoading = true
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
        })

        const { roles } = this.$store.getters['auth/user']
        const canAccess = roles.some(role => ['Dashboard User', 'Super Admin'].includes(role))

        if (!canAccess) {
          await this.$store.dispatch('auth/logout')
        }

        this.$router.push({ name: 'dashboard' }, undefined, () => {
          this.$router.go()
        })
      } catch (error) {
        this.pageError = error.code === 'request.unauthorized' ? 'Invalid credentials' : 'Something went wrong'
        this.isLoginLoading = false
      }
    },
    async submitForgotRequest() {
      try {
        this.pageError = ''
        await this.$store.dispatch('auth/forgot', {
          email: this.email,
        })
        await this.$bvToast.toast(`Passordet ble sendt`, {
          variant: 'success',
          title: 'Nytt passord ble sendt',
          autoHideDelay: 5000,
        })
        this.forgot = false
      } catch (error) {
        this.pageError = error.message
      }
    },
  },
}
</script>
