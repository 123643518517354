<template>
  <!-- BEGIN login -->
  <div class="login">
    <!-- BEGIN login-content -->
    <div class="login-content">
      <validation-observer v-slot="{ invalid }">
        <form @submit.prevent="submitResetPasswordRequest">
          <h1 class="text-center">Nytt passord</h1>
          <div class="text-muted text-center mb-4">
            Angi din epost og ditt nye passord.
          </div>

          <b-alert :show="!!pageError" variant="danger">
            {{ pageError }}
          </b-alert>

          <validation-provider name="E-postadresse" v-slot="context" rules="required|email">
            <b-form-group label="E-postadresse" label-for="email-address">
              <b-input
                v-model="form.email"
                id="email-address"
                type="email"
                size="lg"
                class="fs-15px"
                autocomplete="username"
                placeholder="username@youwish.no"
                :state="getValidationState(context)"
                aria-describedby="email-address-error"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback id="email-address-error" v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider name="Nytt passord" rules="required" v-slot="context">
            <b-form-group>
              <div class="d-flex">
                <label for="password">Nytt passord</label>
              </div>
              <b-input
                id="password"
                type="password"
                size="lg"
                class="fs-15px"
                v-model="form.password"
                :state="getValidationState(context)"
                aria-describedby="password-error"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback id="password-error" v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider name="Gjenta Nytt Passord" rules="required" v-slot="context">
            <b-form-group>
              <div class="d-flex">
                <label for="password">Gjenta Nytt Passord</label>
              </div>
              <b-input
                id="password-confirmation"
                type="password"
                size="lg"
                class="fs-15px"
                v-model="form.password_confirmation"
                :state="getValidationState(context)"
                aria-describedby="password-error"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback id="password-error" v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <button type="submit" class="btn btn-primary btn-lg btn-block fw-500 mb-3" :disabled="invalid">
            Opprett nytt passord
          </button>
        </form>
      </validation-observer>
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login -->
</template>

<script>
import AppOptions from '../config/app-options'
import { getValidationState } from './page-helpers'

export default {
  name: 'ResetPasswordPage',
  inject: ['$cannot'],
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
      },
      pageError: '',
    }
  },
  mounted() {
    AppOptions.appWithoutSidebar = true
    AppOptions.appWithoutHeader = true
    AppOptions.appContentFullWidth = true
    this.form.token = this.$route.query.token
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appWithoutSidebar = false
    AppOptions.appWithoutHeader = false
    AppOptions.appContentFullWidth = false
    next()
  },
  methods: {
    getValidationState,
    async submitResetPasswordRequest() {
      await this.$store.dispatch('auth/resetPassword', this.form).then(async () => {
        this.form.email = ''
        this.form.password = ''
        this.form.password_confirmation = ''

        this.$bvToast.toast(`Ditt nye passord er lagret. Klikk her for å logge inn.`, {
          variant: 'success',
          title: 'Vi har oppdatert ditt passord',
          autoHideDelay: 10000,
        });

        await this.$store.dispatch('auth/me');

        this.$router.push({ name: 'dashboard' });
      }).catch(response => this.pageError = response.message)
    },
  },
}
</script>
